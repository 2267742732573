@import "src/uikit/colors.module";
@import "src/uikit/mixins.module";

.container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 40px 50px;
}

.row {
  display: flex;
  justify-content: space-between;
}

.wowText {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 60px;
  max-width: 60%;
  line-height: 1.1em;

  & img {
    width: 64px;
  }
}

.stepsRow {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 61px;
  margin-top: 56px;
}

.bicepsImg {
  width: 71px;
}

.activateKeyScreenshot {
  width: 50%;
}

.step {
  display: grid;
  grid-template-columns: auto auto;
  row-gap: 48px;
  column-gap: 15px;
  height: min-content;
}

.step > :nth-child(1) {
  grid-column: 1/2;
  width: min-content;
}

.step > :nth-child(2) {
  grid-column: 2/3;
}

.step > :nth-child(3) {
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stepsRow :nth-child(3) .generateWrapper {
  margin-top: 0;
  grid-column: 2/3;
}

.step.stretch {
  align-items: stretch;
}

.generateButton {
  border: none;
  outline: none;
  width: 100%;
  cursor: pointer;
  padding: 16px;
  color: white;
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
  background-color: $accent-red;
  @include transform;
}

.stepGrayText {
  color: #11062880;
  font-size: 16px;
  max-width: 226px;
  text-align: center;
  margin-top: 41px;
}

.qrCode {
  width: 140px;
}

.organizationLogo {
  width: 200px
}

.stepText {
  display: flex;
  column-gap: 20px;
  font-size: 18px;
}

.stepNum {
  font-size: 60px;
  line-height: 1.3em;
  font-weight: 800;
}

.licenceKey {
  padding: 12px;
  border: 2px solid black;
  border-radius: 12px;
  text-align: center;
  font-size: 24px;
  cursor: pointer;
  font-weight: 700;
  width: 90%;
  @include transform;
}

.mobileLinks {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  align-items: center;

  & img {
    width: 180px;
  }
}

.generateWrapper {
  margin-top: 61px;
  grid-column: 2/3;
}

@media (max-width: 500px) {
  .container {
    padding: 20px 20px 100px;
  }

  .stepsRow {
    display: flex;
    flex-direction: column;
    row-gap: 95px;
  }

  .row {
    flex-direction: column;
    align-items: center;
  }

  .wowText {
    font-size: 32px;
    font-weight: 500;
    max-width: 330px;
    margin: 0 auto;

    & img {
      width: 30px;
    }
  }

  .activateKeyScreenshot {
    width: 100%;
  }

  .bicepsImg {
    width: 36px;
  }


  .organizationLogo {
    width: 140px;
    margin-top: 36px;
  }

  .generateButton {
    width: 90%;
  }

  .stepsRow :nth-child(3) .generateWrapper {
    grid-column: 1/3;
  }

  .step > :nth-child(3) {
    grid-column: 1/3;
  }
}

@media (min-width: 2000px) {
  .stepGrayText {
    font-size: 18px;
  }

  .stepText {
    font-size: 22px;
  }

  .organizationLogo {
    width: 250px;
  }
}